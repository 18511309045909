import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoading, selectLoaded, selectEvents } from '../selectors/events.selector';
import { eventsActions } from '../actions/events.actions';

@Injectable({
    providedIn: 'root',
})
export class EventsFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    loading$ = this.store.select(selectLoading);
    events$ = this.store.select(selectEvents);

    getEvents(month: number, year: number) {
        this.store.dispatch(eventsActions.getEvents({ month, year }));
    }

    clear(): void {
        this.store.dispatch(eventsActions.clear());
    }
}
