import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, switchMap } from 'rxjs';
import { eventsActions } from '../actions/events.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class EventsEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getEvents$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(eventsActions.getEvents),
            switchMap(({ month, year }) => {
                return this.httpService.getEvents(month, year).pipe(
                    map((events) => eventsActions.getEventsSuccess({ events })),
                    catchError(() => of(eventsActions.getEventsError())),
                );
            }),
        );
    });
}
