import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { EVENTS_FEATURE_KEY } from './keys';
import { eventsReducer } from './reducers/events.reducer';
import { EventsEffects } from './effects/events.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(EVENTS_FEATURE_KEY, eventsReducer), EffectsModule.forFeature([EventsEffects])],
})
export class EventsStoreModule {}
