import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/shared/models';
import { EventDto, Event } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getEvents(month: number, year: number): Observable<Event[]> {
        const timestampStart = new Date(year, month, 1).getTime() / 1000;
        const timestampEnd = new Date(year, month + 1, 1).setSeconds(-1) / 1000;

        return this.httpClient
            .get<ApiResponse<EventDto[]>>(`api/v1/event?periodStart=${timestampStart}&periodEnd=${timestampEnd}`)
            .pipe(map((response) => response.data.map((v) => new Event(v)).filter((v) => v.date)));
    }
}
