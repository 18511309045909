import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Event } from '../models';

export const eventsActions = createActionGroup({
    source: 'Events',
    events: {
        'Get Events': props<{ year: number; month: number }>(),
        'Get Events Success': props<{ events: Event[] }>(),
        'Get Events Error': emptyProps(),

        Stop: emptyProps(),
        Clear: emptyProps(),
    },
});
