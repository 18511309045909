import { createReducer, on } from '@ngrx/store';
import { eventsActions } from '../actions/events.actions';
import { Event } from '../models';

export interface EventsState {
    loaded: boolean;
    loading: boolean;
    events: Event[];
}

export const initialState: EventsState = {
    loaded: false,
    loading: true,
    events: [],
};

export const eventsReducer = createReducer(
    initialState,
    on(
        eventsActions.getEvents,
        (state): EventsState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        eventsActions.getEventsSuccess,
        (state, action): EventsState => ({
            ...state,
            loaded: true,
            loading: false,
            events: action.events,
        }),
    ),
    on(
        eventsActions.getEventsError,
        (state): EventsState => ({
            ...state,
            loading: false,
        }),
    ),
    on(
        eventsActions.clear,
        (): EventsState => ({
            ...initialState,
        }),
    ),
);
