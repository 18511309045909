import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { EventsFacade } from '../facades/events.facade';

export function eventsCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const eventsFacade = inject(EventsFacade);
        eventsFacade.clear();

        return true;
    };
}
