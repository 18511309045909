import { EventType } from '../types';
import { EventDto } from './event.dto';

export class Event {
    id: string;
    name: string;
    type: EventType;
    description: string;
    date: Date;
    albumId: string | null;

    constructor(data: EventDto) {
        this.id = data.id;
        this.name = data.title ?? data.place;
        this.type = data.type;
        this.description = data.description ?? data.postText;
        this.date = new Date(data.dateTs * 1000);
        this.albumId = data.albumId ?? null;
    }
}
